<template>
  <div>
    <v-row>
      <v-col cols="3">
        <v-sheet class="pa-4">
          <v-avatar color="grey darken-1" size="60"
            ><img :src="data_user.avatar" alt="Avatar" srcset="" width="60"
          /></v-avatar>
        </v-sheet>
      </v-col>
      <v-col cols="9" style="height: 60px" class="mt-4">
        <h4>{{ data_user.username }}</h4>
        <router-link to="" custom>Change Profile Photo</router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" style="height: 60px" class="mt-4">
        <v-text-field
          v-model="name"
          :error-messages="nameErrors"
          :counter="10"
          label="Name"
          required
          @input="$v.name.$touch()"
          @blur="$v.name.$touch()"
        ></v-text-field>
      </v-col>
      <v-col cols="12" style="height: 60px" class="mt-4">
        <v-text-field
          v-model="name"
          :error-messages="nameErrors"
          :counter="10"
          label="Email"
          required
          @input="$v.name.$touch()"
          @blur="$v.name.$touch()"
        ></v-text-field>
      </v-col>
      <v-col cols="12" style="height: 60px" class="mt-4">
        <v-text-field
          v-model="name"
          :error-messages="nameErrors"
          :counter="10"
          label="Telefone"
          required
          @input="$v.name.$touch()"
          @blur="$v.name.$touch()"
        ></v-text-field>
      </v-col>
      <v-col cols="12" style="height: 60px" class="mt-4">
        <v-text-field
          v-model="birth"
          type="date"
          label="Data de Nascimento"
        ></v-text-field>
      </v-col>

      <v-col cols="12" style="height: 60px" class="mt-4">
        <v-select
          v-model="genre"
          :items="[
            { value: 'M', text: 'Masculino' },
            { value: 'F', text: 'Feminino' },
          ]"
          label="Sexo"
        ></v-select>
      </v-col>

      <v-col cols="12" style="height: 60px" class="mt-4">
        <v-btn color="primary" block>SALVE</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: ["data_user"],
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>